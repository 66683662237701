import React, { useState, useEffect } from 'react';

const UserList = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        // Effectuez une requête GET pour récupérer les utilisateurs
        fetch('/get-users')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Mettez à jour l'état avec les données récupérées
                setUsers(data);
            })
            .catch(error => {
                console.error('Erreur :', error);
                // Gérez les erreurs de récupération des données ici
            });
    }, []);

    return (
        <div>
            <h2>Test récupération d'utilisateurs</h2>
            <ul>
                {users.map(user => (
                    <li key={user.id}>
                        Nom : {user.name}<br />
                        Email : {user.email}<br />
                        {/* Autres champs d'utilisateur */}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UserList;
