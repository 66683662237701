import QRCode from 'react-qr-code';
import React, { useContext, useState } from 'react';
import { DataContext } from '../context/DataContext';

const GeneratorTable = () => {
    const { data, setData } = useContext(DataContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [participation, setParticipation] = useState(1);

    const handleAddData = () => {
        const newData = [...data, { id: data.length + 1, firstName, lastName,  participation }];
        setData(newData);
        setFirstName('');
        setLastName('');
        setParticipation(1);
    };

    return (
        <div className="createQR">
            <h2>Test générateur QR Code</h2>
            <form className="formQR">
                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Nom"/>
                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Prénom"/>
                <label><input type="radio" value={1} checked={participation === 1} onChange={() => setParticipation(1)} />Je participe</label>
                <label><input type="radio" value={0} checked={participation === 0} onChange={() => setParticipation(0)} />Je ne participe pas</label>
                <button type="button" onClick={handleAddData}>Ajouter à la liste</button>
            </form>

            <div className="tableQR">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>participation</th>
                            <th>QR Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.lastName}</td>
                            <td>{item.firstName}</td>
                            <td>{item.participation}</td>
                            <td>
                                <QRCode value={`${item.id}, ${item.lastName}, ${item.firstName}, ${item.participation}`} />
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default GeneratorTable;
