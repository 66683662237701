import React, { useState } from 'react';

const LoginForm = () => {
    const [formData, setFormData] = useState({
        email: 'g.liard@mouvementcom.fr',
        password: 'Liard123',
    });

    // Déclarez une variable d'état pour les erreurs
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // REGEX   
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?!.*['";\\]).{8,}$/;

        const newErrors = {};

        if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Adresse email invalide.';
        }
        if (!passwordRegex.test(formData.password)) {
            newErrors.password = 'Le mot de passe doit comporter au moins 8 caractères, 1 lettre majuscule et 1 chiffre.';
        }

        // Effectuer la requête POST vers le backend pour vérifier les informations de connexion
        if (Object.keys(newErrors).length === 0) {
            fetch('https://meteor-mvt.fr/account/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
            .then(response => {
                console.log(response);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                if (data.message) {
                    alert(data.message);
                } else {
                    alert('Erreur lors de l\'inscription. Veuillez réessayer.');
                }
            })
            .catch(error => {
                console.log('Erreur :', error);
            });
        }
        setErrors(newErrors); // Mettez à jour l'état des erreurs
    };

    return (
        <div>
            <h2>Test formulaire de connexion</h2>
            <form onSubmit={handleSubmit} className="formLogin" method="POST">
                <label>
                    Email : <input type="email" name="email" value={formData.email} onChange={handleChange} required/>
                    {errors.email && <div className="error">{errors.email}</div>}
                </label>
                <label>
                    Mot de passe : <input type="password" name="password" value={formData.password} onChange={handleChange} required/>
                    {errors.password && <div className="error">{errors.password}</div>}
                </label>
                <button type="submit">Se connecter</button>
            </form>
        </div>
    );
};

export default LoginForm;
