import './App.scss';
import Scanner from './components/Scanner';
import GeneratorTable from './components/GeneratorTable';
import SignupForm from './components/SignupForm';
import GetUser from './components/GetUser';
import LoginForm from './components/LoginForm';
import { DataProvider } from './context/DataContext';


function App() {
	return (
		<div className="App">
			<DataProvider>
				<GeneratorTable />
				{/* <Scanner /> */}
				<SignupForm />
				<GetUser />
				<LoginForm />
			</DataProvider>
		</div>
	);
}

export default App;
