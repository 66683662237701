import React, { useState } from 'react';

const SignupForm = () => {
    const [formData, setFormData] = useState({
        name: 'Liard',
        email: 'g.liard@mouvementcom.fr',
        password: 'Liard123',
        right_user : 1,
    });

    // Déclarez une variable d'état pour les erreurs
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

     // Fonction handleSubmit pour traiter la soumission du formulaire
    const handleSubmit = (e) => {
        e.preventDefault();

        // REGEX
        const nameRegex = /^[A-Za-z\s'-]+$/;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?!.*['";\\]).{8,}$/;
        const rightRegex = /^[1-3]$/;

        const newErrors = {};
        
        if (!nameRegex.test(formData.name)) {
            newErrors.name = 'Les caractères spéciaux et les chiffres ne sont pas autorisés.';
        }
        if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Adresse email invalide.';
        }
        if (!passwordRegex.test(formData.password)) {
            newErrors.password = 'Le mot de passe doit comporter au moins 8 caractères, 1 lettre majuscule et 1 chiffre.';
        }
        if (!rightRegex.test(formData.right_user)) {
            newErrors.right_user = 'Erreur';
        }

        if (Object.keys(newErrors).length === 0) {
            // Envoi de la requête POST au serveur pour l'inscription
            fetch('https://meteor-mvt.fr/account/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                if (data.message) {
                    alert(data.message);
                } else {
                    alert('Erreur lors de l\'inscription. Veuillez réessayer.');
                }
            })
            .catch(error => {
                console.log('Erreur :', error);
            });
        }
        setErrors(newErrors); // Mettez à jour l'état des erreurs
    };

    return (
        <form className="formCreateUser" method="POST" onSubmit={handleSubmit}>
            <label>
                Nom : <input type="text" name="name" value={formData.name} onChange={handleChange} required/>
                {errors.name && <div className="error">{errors.name}</div>}
            </label>
            <label>
                Email : <input type="email" name="email" value={formData.email} onChange={handleChange} required/>
                {errors.email && <div className="error">{errors.email}</div>}
            </label>
            <label>
                Mot de passe : <input type="password" name="password" value={formData.password} onChange={handleChange} required/>
                {errors.password && <div className="error">{errors.password}</div>}
            </label>
            <label>
                Rôle :
                <select name="right_user" id="right_user" value={formData.right_user} onChange={handleChange}>
                    <option value="1">Administrateur</option>
                    <option value="2">Équipe Event</option>
                    <option value="3">Client</option>
                </select>
                {errors.right_user && <div className="error">{errors.right_user}</div>}
            </label>
            <input type="submit" value="S'inscrire" />
        </form>
    );
};

export default SignupForm;
